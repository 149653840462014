.container {
  max-width: 100vw;
  padding: 0 15px;
  margin: 0 auto;
}

.nav-bar{
  max-width: 100vw;
  width: 100vw;
}

.header-wrap {
  background: #87ebbc;
  border: 4px solid #010101;
  box-shadow: 2px 2px 40px rgba(170, 170, 170, 0.2);
  border-radius: 100px;
  padding: 11px 24px 10px 44px;
}
.site-header .container {
  max-width: 1070px;
}
.header-row {
  display: flex;
  align-items: center;
}
.header-col-left {
  max-width: 208px;
  width: 100%;
}
.header-col-right {
  width: 100%;
  max-width: 120px;
}
.header-col-center {
  flex: 1;
}
.header-menu-items {
  display: flex;
  align-items: center;
}
.header-logo {
  display: flex;
}
.header-btn-link {
  background: #010101;
  box-shadow: 0px 7px 30px -10px rgba(0, 123, 255, 0.1);
  border-radius: 100px;
  max-width: 120px;
  width: 100%;
  justify-content: center;
  line-height: 43px;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}
.menu-link {
  font-family: "Nunito", sans-serif;
  padding: 0 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1e1e1e;
}

a {
  text-decoration: none;
  color: inherit;
}

@media(max-width: 991px){
  .work-col {
      width: 33.33%;
      margin-bottom: 30px;
  }
  .block-inner img {
      width: 100%;
  }
}
@media(max-width: 767px){
  .header-col-center {
      display: none;
  }
  .banner-right-col {
      display: none;
  }
  .home-block-col {
      width: 100%;
  }
  .header-row {
      justify-content: space-between;
  }
  .work-col {
      width: 100%;
  }
  .work-col-inner {
      text-align: center;
  }
  .work-content {
      text-align: left;
  }
  .work-col {
      width: 100%;
      margin-bottom: 60px;
  }
  .work-col-inner img {
      width: auto;
  }
  .banner-left-inner {
      padding: 54px 20px 50px 20px;
  }
  .work-inner-title {
      line-height: 36px;
      margin: 45px 0;
  }
  .center-content {
      font-size: 25px;
      line-height: 30px;
  }
  .header-wrap {
      padding: 11px 20px 10px 35px;
  }
  .site-header {
      margin-top: 19px;
  }
  .home-banner {
      padding: 25px 0 40px;
  }
  .banner-content {
      margin: 15px 0 60px;
  }
  .home-center-wrapper {
      padding: 57px 0 31px;
  }
  p.center-content {
      text-align: left;
      padding: 0 31px;
      font-size: 25px;
      line-height: 30px;
      margin: 0 auto 45px;
  }
  .container {
      max-width: 330px;
  }
  .work-sec .container {
      max-width: 330px;
  }
  .work-title-box {
      margin: 29px 0 29px;
  }
  .site-footer-row {
      flex-wrap: wrap;
      padding: 44px 25px;
  }
}
@media(max-width: 500px){
  .footer-newsletter-row {
      flex-wrap: wrap;
      margin-bottom: 60px;
  }
  .footer-input {
      margin-right: 10px;
      margin-bottom: 20px;
  }
  .site-footer .container {
      max-width: 330px;
  }
}
@media(max-width: 380px){
  .header-wrap {
      padding: 11px 20px 10px 11px;
  }
}