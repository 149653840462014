footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 12px 0;
}
.footer-inner-row {
  display: flex;
  align-items: center;
  width: 100%;
}
.footer-about {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0e2d52;
  padding-right: 10px;
}
.feedback-row {
  display: flex;
  align-items: center;
}
.feed-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #0e2d52;
  padding-right: 8px;
}
.footer-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
.footer-menu-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #0e2d52;
  padding: 0 8px;
}
.footer-menu-link:last-child {
  padding-right: 0;
}
@media (max-width: 767px) {
  .u-toon-box-row {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .utoon-col-left {
    max-width: 100%;
    padding: 25px 0 0;
  }
  .utoon-col-right {
    max-width: 100%;
    padding: 0;
  }
  .footer-row {
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer-col-left {
    width: 100%;
  }
  .footer-inner-row {
    justify-content: center;
  }
  .footer-col-right {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .footer-menu-link:first-child {
    padding-left: 0;
  }
  .utoon-col-left-inner {
    min-height: auto;
  }
}
