.container {
  max-width: 1470px;
  padding: 0 15px;
  margin: 0 auto;
}

.four_squares {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    height: 500px;
    /* background: darkcyan; */
}

.newsletter-text {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #1e1e1e;
    margin: 10px 0px 15px;
  }
  .footer-input {
    background: #ffffff;
    border: 4px solid #010101;
    border-radius: 100px;
    height: 60px;
    padding: 19px 28px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    max-width: 308px;
    width: 100%;
    margin-right: 10px;
  }
  .footer-newsletter-row {
    display: flex;
    align-items: center;
  }
  .footer-submit-button {
    background: #010101;
    box-shadow: 0px 7px 30px -10px rgba(0, 123, 255, 0.1);
    border-radius: 100px;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    max-width: 180px;
    width: 100%;
    height: 63px;
    justify-content: center;
  }
  .site-footer-right {
    max-width: 504px;
    width: 100%;
  }


.i1{
    width: 100%;
    height: 100%;
    border: 1px solid green;
    grid-column: 1/2;
    background-color: yellow;
}

.i2 {
    width: 100%;
    height: 100%;
    border: 1px solid green;
    grid-column: 2/3;
    background-color: green;
}

.i3 {
    width: 100%;
    height: 100%;
    border: 1px solid green;
    grid-column: 1/2;
    grid-row: 2/3;
    background-color: blue;
}

.i4 {
    width: 100%;
    height: 100%;
    border: 1px solid green;
    grid-column: 2/3;
    grid-row: 2/3;
    background-color: purple;
}

.flip_between {
  perspective: 1000px; /* Add perspective for the 3D effect */
  width: 100%;
  height: 500px;
  position: relative;
}

.flip_between > div {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back face of the flipped element */
  transition: transform 0.8s; /* Add transition for smooth flipping */
  transform-style: preserve-3d; /* Preserve 3D transformations */
}

.flip_between > div:first-child {
  transform: rotateY(180deg); /* Initially rotate the four squares div */
}

.flip_between:hover > div:first-child {
  transform: rotateY(0deg); /* Rotate the four squares div back to 0 degrees on hover */
}

.flip_between:hover > div:last-child {
  transform: rotateY(180deg); /* Rotate the image div to 180 degrees on hover */
}

/* Your existing styles for .four_squares, .i1, .i2, .i3, and .i4 */
.get-color-book{
  background-color: orange;
}

.banner-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #1e1e1e;
}
.home-banner {
  padding: 58px 0 40px;
}
.home-banner-wrapper {
  background: #fd6c57;
  border: 4px solid #010101;
  box-shadow: 8px 8px 0px #010101;
  border-radius: 35px;
}
.home-banner-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner-left-inner {
  padding-left: 44px;
  max-width: 864px;
}
.banner-content {
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  margin: 0 0 37px;
  color: #ffffff;
  font-family: "Nunito", sans-serif;
}
.create-card {
  background: #010101;
  box-shadow: 0px 7px 30px -10px rgba(0, 123, 255, 0.1);
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  width: 180px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media(max-width: 991px){
  .work-col {
      width: 33.33%;
      margin-bottom: 30px;
  }
  .block-inner img {
      width: 100%;
  }
}
@media(max-width: 767px){
  .header-col-center {
      display: none;
  }
  .banner-right-col {
      display: none;
  }
  .home-block-col {
      width: 100%;
  }
  .header-row {
      justify-content: space-between;
  }
  .work-col {
      width: 100%;
  }
  .work-col-inner {
      text-align: center;
  }
  .work-content {
      text-align: left;
  }
  .work-col {
      width: 100%;
      margin-bottom: 60px;
  }
  .work-col-inner img {
      width: auto;
  }
  .banner-left-inner {
      padding: 54px 20px 50px 20px;
  }
  .work-inner-title {
      line-height: 36px;
      margin: 45px 0;
  }
  .center-content {
      font-size: 25px;
      line-height: 30px;
  }
  .header-wrap {
      padding: 11px 20px 10px 35px;
  }
  .site-header {
      margin-top: 19px;
  }
  .home-banner {
      padding: 25px 0 40px;
  }
  .banner-content {
      margin: 15px 0 60px;
  }
  .home-center-wrapper {
      padding: 57px 0 31px;
  }
  p.center-content {
      text-align: left;
      padding: 0 31px;
      font-size: 25px;
      line-height: 30px;
      margin: 0 auto 45px;
  }
  .container {
      max-width: 330px;
  }
  .work-sec .container {
      max-width: 330px;
  }
  .work-title-box {
      margin: 29px 0 29px;
  }
  .site-footer-row {
      flex-wrap: wrap;
      padding: 44px 25px;
  }
}
@media(max-width: 500px){
  .footer-newsletter-row {
      flex-wrap: wrap;
      margin-bottom: 60px;
  }
  .footer-input {
      margin-right: 10px;
      margin-bottom: 20px;
  }
  .site-footer .container {
      max-width: 330px;
  }
}
@media(max-width: 380px){
  .header-wrap {
      padding: 11px 20px 10px 11px;
  }
}