.wrapper {
  width: 500px;
}

.label {
  font-size: 20px;
  color: green;
}

/* CSS for CustomModal component */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.genrateSpriteTitle {
  color: blue; /* Text color */
  font-weight: bold; /* Bold text */
  font-size: 24px; /* Adjust font size as needed */
  margin-bottom: 20px;
}

.progressbar-container {
  width: 100%;
  text-align: center;
}

.wrapper {
  width: 100%;
}

.currentStepDescription {
  color: blue; /* Text color */
  font-weight: bold; /* Bold text */
  font-size: 18px; /* Adjust font size as needed */
  margin-top: 20px;
}
