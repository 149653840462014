.container {
  max-width: 1470px;
  padding: 0 15px;
  margin: 0 auto;
}

.site-footer-row {
  background: #ffecaf;
  border: 4px solid #010101;
  box-shadow: 8px 8px 0px #010101;
  border-radius: 24px;
  padding: 44px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer-list-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1e1e1e;
  text-align: center;
}
.footer-logo {
  margin-bottom: 21px;
}
.footer-list {
  max-width: 171px;
  text-align: left;
  margin-bottom: 28px;
}
ul.footer-menu-list {
  padding-left: 52px;
}
.copyright-text p {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: rgba(59, 59, 59, 0.6);
}
.newsletter-text {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #1e1e1e;
  margin: 10px 0px 15px;
}
.footer-input {
  background: #ffffff;
  border: 4px solid #010101;
  border-radius: 100px;
  height: 60px;
  padding: 19px 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: rgba(30, 30, 30, 0.36);
  max-width: 308px;
  width: 100%;
  margin-right: 10px;
}
.footer-newsletter-row {
  display: flex;
  align-items: center;
}
.footer-submit-button {
  background: #010101;
  box-shadow: 0px 7px 30px -10px rgba(0, 123, 255, 0.1);
  border-radius: 100px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  max-width: 180px;
  width: 100%;
  height: 63px;
  justify-content: center;
}
.site-footer-right {
  max-width: 504px;
  width: 100%;
}
.site-footer {
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .work-col {
    width: 33.33%;
    margin-bottom: 30px;
  }
  .block-inner img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .header-col-center {
    display: none;
  }
  .banner-right-col {
    display: none;
  }
  .home-block-col {
    width: 100%;
  }
  .header-row {
    justify-content: space-between;
  }
  .work-col {
    width: 100%;
  }
  .work-col-inner {
    text-align: center;
  }
  .work-content {
    text-align: left;
  }
  .work-col {
    width: 100%;
    margin-bottom: 60px;
  }
  .work-col-inner img {
    width: auto;
  }
  .banner-left-inner {
    padding: 54px 20px 50px 20px;
  }
  .work-inner-title {
    line-height: 36px;
    margin: 45px 0;
  }
  .center-content {
    font-size: 25px;
    line-height: 30px;
  }
  .header-wrap {
    padding: 11px 20px 10px 35px;
  }
  .site-header {
    margin-top: 19px;
  }
  .home-banner {
    padding: 25px 0 40px;
  }
  .banner-content {
    margin: 15px 0 60px;
  }
  .home-center-wrapper {
    padding: 57px 0 31px;
  }
  p.center-content {
    text-align: left;
    padding: 0 31px;
    font-size: 25px;
    line-height: 30px;
    margin: 0 auto 45px;
  }
  .container {
    max-width: 330px;
  }
  .work-sec .container {
    max-width: 330px;
  }
  .work-title-box {
    margin: 29px 0 29px;
  }
  .site-footer-row {
    flex-wrap: wrap;
    padding: 44px 25px;
  }
}
@media (max-width: 500px) {
  .footer-newsletter-row {
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  .footer-input {
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .site-footer .container {
    max-width: 330px;
  }
}
@media (max-width: 380px) {
  .header-wrap {
    padding: 11px 20px 10px 11px;
  }
}
