.card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  width: 40%;
  border-radius: 20px;
  background-color: white;
}
.Title {
  text-align: center;
  background-color: white;
}

.container {
  padding: 2px 16px;
  background-color: white;
  border-radius: 20px;
}

.utoon-col-left-inner {
  background: #87ebbc;
  padding: 15px 45px 60px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  min-height: 614px;
}
.utoon-col-left {
  width: 100%;
  margin: 0 200px 0 0;
}

.utoon-logo {
  text-align: right;
}

.step-count-row {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  color: #282c34;
  margin-bottom: 8px;
}
.step-count {
  padding-left: 10px;
}
.steps-color {
  background: #fed723;
  border-radius: 3px;
  display: inline-block;
  width: 50px;
  height: 6px;
  margin: 0 10px;
}
.steps-color.prev-tab {
  background: #88d35a;
}
.steps-color.active {
  background: #0e2d52;
  width: 142px;
}
.step-count-colors {
  display: flex;
  margin: 0 -10px 17px;
  align-items: center;
}
.upload-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #1b4978;
  margin: 0 0 18px;
}
.upload-info {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #282c34;
  margin: 0 0 20px;
}
.upload-direction-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 3.2px;
  color: #282c34;
  margin: 0 0 10px;
}
.directions-content {
  padding-left: 12px;
}
.directions-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #282c34;
  margin: 0 0 15px;
}
.directions-content strong {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.utoon-col-right {
  max-width: 649px;
  width: 100%;
  padding: 0 10px;
}

@media (max-width: 991px) {
  .upload-title {
    font-size: 22px;
    line-height: 30px;
  }

  .utoon-col-right{
    /* center element on page */
    margin-right: auto;
  }
}
