* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif !important;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
.container {
  max-width: 100vw;
  padding: 0 15px;
  margin: 0 auto;
}
button {
  font-family: "Poppins", sans-serif !important;
}
