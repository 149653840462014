input[type="file"] {
  display: none;
}

.pre-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 50px;
  margin-bottom: 22px;
}

.upload-img-box {
  border: 2px solid #0e2d52;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 400px 0px 400px 0px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-uploader {
  display: hidden;
}

.pre-upload-btn {
  background: #000000;
  border: 2px solid #000000;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
  border-radius: 7px;
  width: 100%;
  justify-content: center;
  height: 56px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.pre-upload-btn img {
  margin-right: 7px;
}
.prev-btn {
  background: #87ebbc;
  border: 2px solid #87ebbc;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
  border-radius: 7px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  max-width: 296px;
  cursor: pointer;
}
.next-btn {
  background: #000000;
  border: 2px solid #000000;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
  border-radius: 7px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  margin-left: auto;
  max-width: 296px;
  cursor: pointer;
}
.button-row {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  margin-bottom: 25px;
}
.button-col {
  width: 50%;
  padding: 0 10px;
}
.steps-color {
  background: #fed723;
  border-radius: 3px;
  display: inline-block;
  width: 50px;
  height: 6px;
  margin: 0 10px;
}
.steps-color.prev-tab {
  background: #88d35a;
}
.steps-color.active {
  background: #0e2d52;
  width: 142px;
}


